import { Collection } from '@/store/common/collections.ts';
import ResourceMixin from '@/mixins/ResourceMixin.ts';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useEmployeesStore } from '@/store/employees-store';
import { Service } from '@/model/service';
import { Workplace } from '@/model/workplace';
import { Employee } from '@/model/employee';
import { Timetable } from '@/model/timetable';
import { useTimetablesStore } from '@/store/timetables-store';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import { TimetableDateException } from '@/model/timetable-date-exception';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceMixin>
>).extend({
  mixins: [ResourceMixin],
  computed: {
    ...mapStores(
      useServicesStore,
      useWorkplacesStore,
      useEmployeesStore,
      useTimetablesStore,
      useTimetableExceptionsStore
    ),
    services(): Service[] {
      return this.servicesStore.entities;
    },
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    sendingData(): boolean {
      return this.employeesStore.saving;
    },
    validationErrors(): any {
      return this.employeesStore.validationErrors;
    },
    currentEmployee(): Employee {
      return this.employeesStore.currentEntity;
    },
    currentEmployeeTimetables(): Timetable[] {
      return this.timetablesStore.entities;
    },
    currentEmployeeTimetableExceptions(): TimetableDateException[] {
      return this.timetableExceptionsStore.entities;
    }
  },
  methods: {
    getEmployeeForApi({
      employee,
      preferredServices,
      notPreferredServices,
      preferredWorkplaces,
      notPreferredWorkplaces
    }: {
      employee: Employee;
      preferredServices: Service[];
      notPreferredServices: Service[];
      preferredWorkplaces: Workplace[];
      notPreferredWorkplaces: Workplace[];
    }) {
      const servicesForApi = this.getCollectionWithPreferencesForApi(
        preferredServices,
        notPreferredServices
      );
      const workplacesForApi = this.getCollectionWithPreferencesForApi(
        preferredWorkplaces,
        notPreferredWorkplaces
      );

      employee = {
        ...employee,
        [Collection.SERVICES]: servicesForApi,
        [Collection.WORKPLACES]: workplacesForApi
      };

      return employee;
    }
  }
});
