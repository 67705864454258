


















import EmployeeForm from './EmployeeForm.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import BaseEmployeeMixin from '@/components/employees/BaseEmployeeMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { EmployeePanel, useUiStore } from '@/store/ui-store';
import { Employee } from '@/model/employee';
import { Service } from '@/model/service';
import { Workplace } from '@/model/workplace';
import { Collection } from '@/store/common/collections';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseEmployeeMixin>
>).extend({
  components: {
    BackButtonTitle,
    EmployeeForm
  },
  mixins: [BaseEmployeeMixin],
  props: {
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapStores(useUiStore)
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(EmployeePanel.ADD_EMPLOYEE);
    },
    async addEmployee({
      employee,
      preferredServices,
      notPreferredServices,
      preferredWorkplaces,
      notPreferredWorkplaces
    }: {
      employee: Employee;
      preferredServices: Service[];
      notPreferredServices: Service[];
      preferredWorkplaces: Workplace[];
      notPreferredWorkplaces: Workplace[];
    }) {
      employee = this.getEmployeeForApi({
        employee,
        preferredServices,
        notPreferredServices,
        preferredWorkplaces,
        notPreferredWorkplaces
      });
      const employeeCreated: Partial<Employee> = await this.employeesStore.create(
        employee
      );

      if (employeeCreated) {
        this.closeCurrentPanel();
      }
      if (!this.withAssociations) {
        this.uiStore.addNotification(Collection.SERVICES);
      }
    }
  }
});
