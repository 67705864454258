






























































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Search from '@/components/shared/Search.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { Service } from '@/model/service';
import { Category } from '@/model/category';
import { mapStores } from 'pinia';
import {
  ServicePanel,
  SERVICES_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { useServicesStore } from '@/store/services-store';
import { useCategoriesStore } from '@/store/categories-store';
import { Collection } from '@/store/common/collections';
import ServicesSort from '@/components/services/ServicesSort.vue';
import ConfigListElement from '@/components/shared/ConfigListElement.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    ServicesSort,
    LoadingIndicator,
    Search,
    EmptyDataset,
    ConfigListElement
  },
  mixins: [ResourceConfigMixin],
  data() {
    return {
      searchTerms: '',
      COLLECTION: Collection
    };
  },
  computed: {
    ...mapStores(useUiStore, useServicesStore, useCategoriesStore),
    services(): Service[] {
      return this.servicesStore.entities;
    },
    categories(): Category[] {
      return this.categoriesStore.entities;
    },
    currentService(): Service {
      return this.servicesStore.currentEntity;
    },
    isFetchingServices(): boolean {
      return this.servicesStore.fetching;
    },
    isFetchingCategories(): boolean {
      return this.categoriesStore.fetching;
    },
    sortPerformedOrder(): boolean {
      return this.servicesStore.sortPerformedOrder;
    }
  },
  methods: {
    sortingServices(value) {
      this.servicesStore.toggleSortPerformedOrder(value);
    },
    getServicesForCategoryId(categoryId: number) {
      return this.servicesStore.getServicesForCategoryId(categoryId);
    },
    editService(service: Service) {
      this.uiStore.openPanel(SERVICES_PANELS_LAYOUT, ServicePanel.EDIT_SERVICE);
      this.servicesStore.setCurrentEntity(service);
    },
    getServicesFiltered(servicesFromCategory: Service[]) {
      if (this.searchTerms) {
        return servicesFromCategory.filter(service => {
          const splitVal = this.searchTerms.split(' ');
          const joinedSplitVal = splitVal.join('.*');
          const regex = new RegExp(joinedSplitVal, 'gi');
          return regex.test(`${service.no}${service.name}`);
        });
      }
      return servicesFromCategory;
    }
  }
});
