











































import ServiceForm from '@/components/services/ServiceForm.vue';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import { Collection } from '@/store/common/collections.ts';
import BaseServiceMixin from '@/components/services/BaseServiceMixin.ts';
import difference from 'lodash/difference';
import resourcesService from '@/services/resource.service';
import ResourceMixin from '@/mixins/ResourceMixin.ts';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { AlertType, ServicePanel, useUiStore } from '@/store/ui-store';
import { camelCaseToUnderscore } from '@/helpers/collection-name.helper';
import { Service } from '@/model/service';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof ResourceMixin> &
    InstanceType<typeof BaseServiceMixin> &
    InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    CheckEventsConflictsModal,
    ServiceForm,
    ConfirmModal,
    BackButtonTitle,
    PlainButton
  },
  mixins: [ResourceMixin, BaseServiceMixin, ResourceConfigMixin],
  data() {
    return {
      unsubscribeFromStore: null as any,
      serviceBeingSaved: null as any,
      deleteServiceModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(useUiStore, useServicesStore)
  },
  created() {
    this.unsubscribeFromStore = this.servicesStore.$onAction(
      ({
        name, // name of the action
        after // hook after the action returns or resolves
      }) => {
        if (name === 'findAll') {
          after(result => {
            this.servicesStore.currentEntity = this.servicesStore.entities.find(
              service => {
                return service.id === this.currentService.id;
              }
            ) as Service;
          });
        }
      }
    );
  },
  mounted() {
    this.deleteServiceModal = (this.$refs
      .deleteServiceModal as unknown) as IConfirmModal;
  },
  destroyed() {
    this.unsubscribeFromStore();
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(ServicePanel.EDIT_SERVICE);
      this.servicesStore.unsetCurrentEntity();
      this.servicesStore.unsetValidationErrors();
    },
    openDeleteServiceModal() {
      this.deleteServiceModal.openModal(
        this.$t('confirmModal.deleteService.message', {
          serviceName: this.currentService.name
        }),
        {
          okTitle: this.$t('button.delete'),
          okVariant: 'danger'
        }
      );
    },
    async onConfirmServiceDeletion() {
      const serviceSuccessfullyDeleted = await this.servicesStore.delete(
        this.currentService
      );
      this.deleteServiceModal.sendingData = false;
      if (serviceSuccessfullyDeleted) {
        this.deleteServiceModal.closeModal();
        this.closeCurrentPanel();
        this.uiStore.alert(this.$t('toast.serviceDeleted'), AlertType.SUCCESS);
      }
    },
    async submit({
      service,
      preferredEmployees,
      notPreferredEmployees,
      machineModels,
      workplaces,
      childServices
    }) {
      this.serviceBeingSaved = {
        service,
        preferredEmployees,
        notPreferredEmployees,
        machineModels,
        workplaces,
        childServices
      };
      if (
        difference(
          this.currentService.workplaces.map(workplace => workplace.id),
          workplaces.map(workplace => workplace.id)
        ).length > 0
      ) {
        try {
          this.servicesStore.saving = true;
          await this.verifyRequest();
          await this.editService();
        } catch (e) {
          this.servicesStore.saving = false;
          this.$bvModal.show('check-events-conflicts-modal');
        }
      } else {
        await this.editService();
      }
    },
    async editService() {
      const employeesForApi = this.getCollectionWithPreferencesForApi(
        this.serviceBeingSaved.preferredEmployees,
        this.serviceBeingSaved.notPreferredEmployees
      );
      const workplacesForApi = this.getCollectionForApi(
        this.serviceBeingSaved.workplaces
      );
      const machineModelsForApi = this.getCollectionForApi(
        this.serviceBeingSaved.machineModels
      );
      const childServicesForApi = this.getCollectionForApi(
        this.serviceBeingSaved.childServices
      );

      this.serviceBeingSaved.service = {
        ...this.serviceBeingSaved.service,
        [Collection.EMPLOYEES]: employeesForApi,
        [camelCaseToUnderscore(Collection.MACHINE_MODELS)]: machineModelsForApi,
        [Collection.WORKPLACES]: workplacesForApi,
        [camelCaseToUnderscore(Collection.CHILD_SERVICES)]: childServicesForApi
      };

      await this.servicesStore.edit(this.serviceBeingSaved.service, [
        Collection.WORKPLACES,
        Collection.EMPLOYEES,
        Collection.MACHINE_MODELS
      ]);
    },
    async verifyRequest() {
      const verifyPromises = difference(
        this.currentService.workplaces.map(workplace => workplace.id),
        this.serviceBeingSaved.workplaces.map(workplace => workplace.id)
      ).map(workplaceId =>
        resourcesService.verifyRemoveServicesFromWorkplace(workplaceId, {
          serviceIds: [this.currentService.id]
        })
      );
      await Promise.all(verifyPromises);
    }
  }
});
