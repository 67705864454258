var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.sortPerformedOrder ? 'panel panel-full' : 'panel']},[(_vm.isFetchingCategories || _vm.isFetchingServices)?_c('loading-indicator'):_vm._e(),(!_vm.isFetchingCategories && !_vm.isFetchingServices)?_c('div',[(_vm.sortPerformedOrder)?_c('services-sort',{on:{"exit":function($event){return _vm.sortingServices(false)}}}):_c('div',[_c('search',{attrs:{"done-typing-interval":0,"default-value":_vm.searchTerms,"placeholder":"Nom ou n° de prestation","icon":"fas fa-filter"},on:{"onSearch":function($event){_vm.searchTerms = $event}}}),(_vm.services.length === 0)?_c('empty-dataset',{attrs:{"text":"Aucune prestation","icon":"concierge-bell"}}):_vm._e(),_vm._l((_vm.categories),function(category){return _c('div',{key:category.id},[(
            _vm.getServicesFiltered(_vm.getServicesForCategoryId(category.id))
              .length > 0
          )?_c('div',[_c('div',{staticClass:"tw-text-sm tw-text-medium"},[_vm._v(" "+_vm._s(category.name)+" ")]),_vm._l((_vm.getServicesFiltered(
              _vm.getServicesForCategoryId(category.id)
            )),function(service){return _c('div',{key:service.id,class:{
              active: _vm.currentService && _vm.currentService.id === service.id
            },on:{"click":function($event){return _vm.editService(service)}}},[_c('config-list-element',{attrs:{"current-entity":_vm.currentService,"entity":service,"collection":_vm.COLLECTION.SERVICES},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" "+_vm._s(service.no)+" ")]},proxy:true},{key:"sub",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("currency")(service.price))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(service.name)+" ")])],1)})],2):_vm._e()])})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }