

































































import ServicesList from '@/components/services/ServicesList.vue';
import AddService from '@/components/services/AddService.vue';
import EditService from '@/components/services/EditService.vue';
import AddWorkplace from '@/components/workplaces/AddWorkplace.vue';
import AddMachine from '@/components/machines/AddMachine.vue';
import AddEmployee from '@/components/employees/AddEmployee.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { useCategoriesStore } from '@/store/categories-store';
import { Service } from '@/model/service';
import { Category } from '@/model/category';
import {
  ServicePanel,
  SERVICES_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { useUsersStore } from '@/store/users-store';
import PlainButton from '@/components/shared/PlainButton.vue';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';

export default Vue.extend({
  components: {
    AddEmployee,
    ServicesList,
    AddService,
    EditService,
    AddWorkplace,
    AddMachine,
    PlainButton,
    ButtonGroup
  },
  data() {
    return {
      SERVICE_PANEL: ServicePanel
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useServicesStore,
      useCategoriesStore,
      useUsersStore
    ),
    services(): Service[] {
      return this.servicesStore.entities;
    },
    categories(): Category[] {
      return this.categoriesStore.entities;
    },
    currentService(): Service {
      return this.servicesStore.currentEntity;
    },
    isFetchingServices(): boolean {
      return this.servicesStore.fetching;
    },
    isFetchingCategories(): boolean {
      return this.categoriesStore.fetching;
    },
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    sortPerformedOrder(): boolean {
      return this.servicesStore.sortPerformedOrder;
    }
  },
  created() {
    this.servicesStore.findAll(this.usersStore.loggedInVendorId, true, false);
    this.uiStore.openPanel(SERVICES_PANELS_LAYOUT, ServicePanel.SERVICES_LIST);

    if (this.currentService?.id) {
      this.uiStore.openPanel(SERVICES_PANELS_LAYOUT, ServicePanel.EDIT_SERVICE);
    }
  },
  methods: {
    isPanelOpened(panel) {
      return this.uiStore.isPanelOpened(panel);
    },
    addService() {
      this.servicesStore.unsetCurrentEntity();
      this.uiStore.openPanel(SERVICES_PANELS_LAYOUT, ServicePanel.ADD_SERVICE);
    },
    closeAddEmployeePanel() {
      this.uiStore.closePanel(ServicePanel.ADD_EMPLOYEE);
    },
    sortingServices(value) {
      this.servicesStore.toggleSortPerformedOrder(value);
    }
  }
});
