
















































import Vue from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { Service } from '@/model/service';
import draggable from 'vuedraggable';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    draggable
  },
  data() {
    return {
      serviceIds: [],
      drag: false
    };
  },
  computed: {
    ...mapStores(useServicesStore, useUsersStore),
    services(): Service[] {
      return this.servicesStore.entities;
    },
    loggedInVendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        easing: 'cubic-bezier(0.32, 0, 0.67, 0)',
        ghostClass: 'tw-bg-primary-200',
        dragClass: 'tw-bg-primary-500'
      };
    }
  },
  watch: {
    services() {
      this.setServicesIds();
    }
  },
  created() {
    this.setServicesIds();
  },
  methods: {
    service(id: number): Service {
      return this.services.find(service => service.id === id);
    },
    setServicesIds() {
      this.serviceIds = [...this.services]
        .sort((a, b) => a.performed_order - b.performed_order)
        .map(service => service.id);
    },
    async saveOrder(): Promise<void> {
      await this.servicesStore.updateServicesOrder(this.serviceIds);
      this.servicesStore.findAll(this.loggedInVendorId, true);
    }
  }
});
